// De javascript van deze widget staat in app.js.
// Dit is zo omdat de javascript gedeeld wordt met de cookieMsg die op alle pagina's terugkomt.
$(function () {
    toggleCookieBlocks();
});

function toggleCookieBlocks() {
    $('.cookieBlock').each(function() {
        var el = $(this);
        var content = $(this).attr('data-content');
        $.ajax({
            url: '/cookieBlocks/' + sts.multisite + '/cookieBlock.' + content,
            context: document.body,
            success: function(data) {
                el.html(data);
            }
        });
    });
}